/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Flex, Text, jsx, Grid } from 'theme-ui';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import Layout from '../components/Layout';

import BusinessCard from '../components/BusinessCard';
// import SearchFilter from '../components/SearchFilter/SearchFilter';

// Todo => Refactor, add more implicit returns.
const Places = ({ data }) => {
  const formatOneOffStrings = str => {
    switch (str) {
      case 'Art ':
        return 'Art & Entertainment';
      case 'Food ':
        return 'Food & Beverage';
      case 'Shopping ':
        return 'Shopping & Retail';
      case 'Event ':
        return 'Event Venue';
      default:
        return str;
    }
  };

  const [searchString, setSearchString] = useState('');

  const [filterOption, setFilterOption] = useState('All');
  const businesses = data.businesses.items.filter(
    el =>
      !el.categories.includes('Commercial property') &&
      el.name !== 'GoNation Area 52'
  );

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    setSearchString(
      params.get('search')
        ? formatOneOffStrings(decodeURI(params.get('search')))
        : ''
    );
  }, []);

  const searchFilter = business => {
    if (searchString === '') {
      return business;
    } else
      return (
        business.name.toLowerCase().includes(searchString.toLowerCase()) ||
        business.categories
          .map(category => category.toLowerCase())
          .includes(searchString.toLowerCase())
      );
  };

  const categoryFilter = business => {
    // Depending on what search category is selected (default is all) we filter to display only matching results. We run this filter function after the search filter
    const categoryGroupName = () =>
      business.categoryGroups.map(category => category.name);

    switch (filterOption) {
      case 'All':
        return business;
      case 'Restaurants':
        return categoryGroupName().includes('Restaurants');
      case 'Services':
        return categoryGroupName().includes('Services');
      case 'Retail':
        return categoryGroupName().includes('Retail');
      case 'For Rent':
        return business.categories.includes('Commercial Real Estate');
    }
  };

  const getColumnEnd = idx => {
    switch (idx) {
      case 0:
        return 'span 2';
      case 1:
        return 'span 2';
      default:
        return '';
    }
  };

  const getGridArea = idx => {
    switch (idx) {
      case 0:
        return `1 / 1 / 4 / 3`;
      case 1:
        return '1 / 3 / 3 / 5';
      case 2:
        return '4 / 1 / 6 / 3';
      case 3:
        return '6 / 1 / 8 / 2';
      case 4:
        return '6 / 2 / 8 / 3';
      case 5:
        return '8 / 1 / 11 / 3';
      case 6:
        return '11 / 1 / 13 / 3';
      case 7:
        return '3 / 3 / 5 / 4';
      case 8:
        return '3 / 4 / 5 / 5';
      case 9:
        return '5 / 3 / 8 / 5';
      case 10:
        return '8 / 3 / 10 / 5';
      case 11:
        return '10 / 3 / 13 / 4';
      case 12:
        return '10 / 4 / 13 / 5';
    }
  };

  const renderMasonry = () => (
    <Grid
      sx={{
        gridTemplateColumns: `repeat(4, 1fr)`,
        gridTemplateRows: `repeat(12, 300px)`,
        gridColumnGap: `25px`,
        gridRowGap: `25px`,
        paddingX: 3,
        pb: 4,
      }}
    >
      {businesses
        .filter(
          business =>
            business.name !== 'Area 52 - A GoNation Secret Site' &&
            business.name !== 'CBD SoNo'
        )
        .filter(business => searchFilter(business))
        .filter(business => categoryFilter(business))
        .map((business, idx) => (
          <Box
            sx={{
              width: ['100%'],
              gridArea: getGridArea(idx),
            }}
          >
            <BusinessCard data={business} isSearching />
          </Box>
        ))}
    </Grid>
  );

  const renderBasic = () => (
    <Box
      sx={{
        paddingX: 3,
        pb: 4,
        paddingTop: [6, 'inherit'],
      }}
    >
      {businesses
        .filter(business => searchFilter(business))
        .filter(business => categoryFilter(business))
        .map((business, idx) => (
          <Box
            sx={{
              width: ['100%'],
              paddingY: 4,
            }}
          >
            <BusinessCard data={business} isSearching />
          </Box>
        ))}
    </Box>
  );

  const renderFlexLayout = () => (
    <Flex
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        // paddingX: 3,
        paddingY: 4,
        pb: 4,
      }}
    >
      {businesses
        .filter(
          business =>
            business.name !== 'Area 52 - A GoNation Secret Site' &&
            business.name !== 'CBD SoNo'
        )
        .filter(business => searchFilter(business))
        .filter(business => categoryFilter(business))
        .map((business, idx) => (
          <Box
            key={idx}
            sx={{
              width: ['100%', '45%', '50%', '30%'],
              paddingX: 2,

              mb: 4,
            }}
          >
            <BusinessCard data={business} isSearching />
          </Box>
        ))}
    </Flex>
  );

  return (
    <Layout pageTitle="Places">
      <Box sx={{ paddingY: 2 }}>
        <Box sx={{ display: ['block', 'block', 'none'] }}>{renderBasic()}</Box>
        <Box sx={{ display: ['none', 'none', 'block'] }}>
          {renderFlexLayout()}
        </Box>
      </Box>
    </Layout>
  );
};

export default Places;

export const query = graphql`
  {
    businesses {
      items {
        categoryGroups {
          name
        }
        avatar
        categories
        contact {
          facebook
          instagram
          phone
          url
        }
        cover
        description
        gonationId
        location {
          address {
            city
            country
            postalCode
            state
            street
          }
        }
        name
        slug
      }
    }
  }
`;
